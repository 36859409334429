import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next-sanity@8.5.0_@sanity+client@6.20.0_@sanity+icons@3.2.0_react@18.2.0__@sanity+types@3.46._nsraxty4abk6gxtamoqusuqxsa/node_modules/next-sanity/dist/visual-editing/VisualEditing.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@babel+core@7.24.3_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@babel+core@7.24.3_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@babel+core@7.24.3_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"app/sections/about-section.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"400\"}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@babel+core@7.24.3_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"components/SectionHead.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"700\"}],\"variableName\":\"robotoSemibold\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@babel+core@7.24.3_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"components/SectionHead.tsx\",\"import\":\"Poppins\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":\"600\"}],\"variableName\":\"PoppinsBold\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@babel+core@7.24.3_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"components/StickyBar.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":\"700\",\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"robotoBold\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.13_@babel+core@7.24.3_react-dom@18.2.0_react@18.2.0__react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"components/StickyBar.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":\"400\",\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"robotoRegular\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/myself/img_0672.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/assets/myself/mobilebanner.png");
